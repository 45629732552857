
export default {
  data() {
    return {
      isStickyButtonVisible: false,
    }
  },

  computed: {
    targetedElements() {
      return document.querySelectorAll('.hide-sticky-button')
    },
  },

  mounted() {
    window.addEventListener('scroll', this.hasTargetedElementsInViewport)
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.hasTargetedElementsInViewport)
  },

  methods: {
    hasTargetedElementsInViewport() {
      const isElementsInViewport = []

      this.targetedElements.forEach(ref => {
        isElementsInViewport.push(
          this.checkVisibility(ref) && this.isInViewport(ref)
        )
      })

      this.isStickyButtonVisible = !isElementsInViewport.includes(true)
    },

    checkVisibility(element) {
      if (typeof element.checkVisibility === 'function') {
        return element.checkVisibility()
      } else {
        return this.isInViewport(element)
      }
    },

    isInViewport(element) {
      const rect = element.getBoundingClientRect()
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      )
    },
  },
}
