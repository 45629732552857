
export default {
  props: {
    queryParam: {
      type: String,
      required: true,
      description: 'The query parameter to check for in the URL.',
    },

    value: {
      type: String,
      default: null,
      description:
        'The value the query parameter must match. If omitted, only the existence of the parameter is checked.',
    },
  },

  computed: {
    isQueryPresent() {
      const { query } = this.$route

      if (this.value) {
        return query[this.queryParam] === this.value
      } else {
        return this.queryParam in query
      }
    },
  },
}
